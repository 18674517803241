import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      < BsInstagram />
    </div>
    <div>
      <FaLinkedinIn />
    </div>
    <div>
      < BsTwitter />
    </div>
  </div>
);

export default SocialMedia;