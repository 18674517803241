import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Contactanos y agenda tu cita</h2>
      <p>Presencial por llamada, email, Whatsapp o Virtual de forma directa</p>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.wa} alt="phone" />
          <a href="tel:+(507) 6704-1694" className="p-text">Agendar por Whatsapp</a>
        </div>
        <a className="app__footer-card"
          id="Setmore_button_iframe"  
          href="https://booking.setmore.com/scheduleappointment/739dc466-3f41-412b-b11c-d106ff2f14a5"
          >
            <img  
            src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.png" 
            alt="Book an appointment with Consultas Doctor Nino using Setmore" 
            className="p-text links-2"/>Agendar Consulta (Virtual)
        </a>
      </div>
      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+(507) 6704-1694" className="p-text">Agendar por Llamada</a>
        </div>
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:nino@doctornino.com" className="p-text">Agendar Consulta por email</a>
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);